import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

const getCookie = (name) => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};

const setCookie = (name, value) => {
  var d = new Date;
  d.setTime(d.getTime() + 24*60*60*1000);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
}

const COOKIE_NAME = 'delved'

const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    rand: Math.ceil(Math.random() * 10),
    delvedCookie: getCookie(COOKIE_NAME)
  }
});

app.ports.setCookie.subscribe(() => setCookie(COOKIE_NAME, true));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
